<script lang="ts" setup>
import warningJSON from "~/resources/lottie_warning.json";
import { Vue3Lottie } from "vue3-lottie";
import { isWebview } from "@dvlden/is-webview";

const deviceDialog = ref(false);

onMounted(() => {
  if (isWebview(window.navigator.userAgent)) {
    deviceDialog.value = true;
  }
});
</script>
<template>
  <v-dialog v-model="deviceDialog" max-width="400">
    <v-card class="pa-4 justify-center align-center">
      <Vue3Lottie
        :animationData="warningJSON"
        :height="100"
        :width="100"
        :loop="1"
      />

      <div class="text--secondary py-4">
        {{ $t("errors.device") }}
      </div>

      <div class="text--secondary py-4">
        <a href="https://support.apple.com/nl-nl/102680">iPhone hulp</a>
        <br />
        <a
          href="https://www.qrcode-tiger.com/nl/how-to-scan-a-qr-code-on-android-without-an-app#Scan_a_QR_code_on_any_Android_device_without_an_app"
          >Android hulp</a
        >
      </div>
      <v-card-actions>
        <v-btn @click.prevent="deviceDialog = false"> close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.qr-dialog {
  z-index: 1;
}
</style>
