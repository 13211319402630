<script lang="ts" setup>
import { useShopStore } from "~/stores/shop";
import qrJSON from "~/resources/lottie_qr.json";
import { Vue3Lottie } from "vue3-lottie";

const shopStore = useShopStore();
const { qrDialog } = storeToRefs(shopStore);

const agree = () => {
  qrDialog.value = false;
};
</script>
<template>
  <v-dialog class="qr-dialog" v-model="qrDialog" max-width="400" persistent>
    <v-card class="pa-4 text-center justify-center align-center">
      <Vue3Lottie
        :animationData="qrJSON"
        :height="200"
        :width="200"
        :loop="1"
      />

      <div class="text--secondary py-4">
        {{ $t("general.qrMessage") }}
      </div>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.qr-dialog {
  z-index: 99;
}
</style>
