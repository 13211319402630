<script lang="ts" setup>
import { useProductSelectionStore } from "~/stores/productSelection";

const productStore = useProductSelectionStore();
const { resumeOrderDialog } = storeToRefs(productStore);
const { getStoredSelection, emptyStoredSelection } = productStore;
const loading = ref(false);

const fetchOrder = async () => {
  loading.value = true;
  await getStoredSelection();
  resumeOrderDialog.value = false;
  loading.value = false;
};

const removeSavedOrder = async () => {
  loading.value = true;
  await emptyStoredSelection();
  resumeOrderDialog.value = false;
  loading.value = false;
};
</script>
<template>
  <v-dialog v-model="resumeOrderDialog" max-width="300" persistent>
    <v-card class="pa-4 text-center">
      <div class="text--primary">
        {{ $t("order.orderFoundHeader") }}
      </div>
      <template v-slot:actions>
        <v-btn variant="flat" class="ml-0" @click="removeSavedOrder">
          {{ $t("order.orderFoundReset") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          class="ml-0 bg-primary"
          @click="fetchOrder"
          :loading="loading"
          :disabled="loading"
        >
          {{ $t("order.orderFoundResume") }}
        </v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>
<style></style>
